import type {ReactNode} from 'react';

import {Heading} from '@/components/typography/heading';
import {cn} from '@/shared';

type Props = {
	children: ReactNode;
	className?: string;
};

export const Heading2 = ({children, className}: Props) => {
	return (
		<Heading level={2} size="3xlarge" spacing className={cn([className])}>
			{children}
		</Heading>
	);
};

export const Heading3 = ({children, className}: Props) => {
	return (
		<Heading level={3} size="2xlarge" spacing className={cn([className])}>
			{children}
		</Heading>
	);
};

export const Heading4 = ({children, className}: Props) => {
	return (
		<Heading level={4} size="xlarge" spacing className={cn([className])}>
			{children}
		</Heading>
	);
};

export const Heading5 = ({children, className}: Props) => {
	return (
		<Heading level={5} size="large" spacing className={cn([className])}>
			{children}
		</Heading>
	);
};

export const Heading6 = ({children, className}: Props) => {
	return (
		<Heading level={6} size="medium" spacing className={cn([className])}>
			{children}
		</Heading>
	);
};
