import type {ReactNode} from 'react';
import clsx from 'clsx';

import {Paragraph} from '@/components/typography/paragraph';

type Props = {
	children: ReactNode;
};

export const Blockquote = ({children}: Props) => {
	return (
		<blockquote
			className={clsx('my-10', 'border-l-4', 'border-secondary', 'pl-4', 'font-semibold')}
		>
			<Paragraph>
				<span className="text-secondary-light">“</span>
				{children}
				<span className="text-secondary-light">”</span>
			</Paragraph>
		</blockquote>
	);
};
