import type {FC} from 'react';

import type {ButtonSize} from '@/components/button';
import {CallToAction} from '@/components/call-to-action/CallToAction';
import {CallToActionGroup} from '@/components/call-to-action-group/CallToActionGroup';
import {resolveLinkHref} from '@/shared';

import type {CallToActionLineFragment} from './query';

interface Props {
	value: CallToActionLineFragment;
	className?: string;
	size?: ButtonSize;
}

export const CallToActionLineContainer: FC<Props> = ({value, className, size}) => {
	return (
		<CallToActionGroup className={className}>
			{value.callToActions?.map((cta) => (
				<CallToAction
					key={cta._key}
					text={cta.text ?? ''}
					color={cta.color}
					size={size ?? 'large'}
					targetBlank={cta.targetBlank}
					href={resolveLinkHref({
						type: cta.type,
						href: cta.href,
						_type: 'link',
						reference: cta.reference,
						targetBlank: cta.targetBlank,
					})}
				/>
			))}
		</CallToActionGroup>
	);
};
