'use client';

import {type FC, useCallback, useState} from 'react';
import clsx from 'clsx';

import {Play} from '@/components/icons/play';
import {usePrefersReducedMotion} from '@/hooks/usePrefersReducedMotion';

interface Props {
	videoId: string;
	title: string;
}

export const VideoPlayerBackground: FC<Props> = ({videoId}: Props) => {
	const hasReducedMotionPreference = usePrefersReducedMotion();

	let videoParams =
		'autoplay=1&loop=1&autopause=0&title=0&byline=0&portrait=0&controls=0&muted=1&background=1';

	if (hasReducedMotionPreference) {
		videoParams =
			'autoplay=0&loop=0&autopause=1&title=0&byline=0&portrait=0&controls=0&muted=1&background=1';
	}

	return (
		<div className={clsx('absolute', 'inset-0', 'overflow-hidden')}>
			<div className={clsx('relative', 'w-screen', 'h-screen', 'overflow-hidden', 'z-[-1]')}>
				<iframe
					aria-hidden="true"
					name="vimeo-player"
					title="vimeo-player"
					src={`https://player.vimeo.com/video/${videoId}?${videoParams}`}
					allow="autoplay; muted;"
					className={clsx(
						'absolute',
						'top-[47%]',
						'left-1/2',
						'-translate-x-1/2',
						'-translate-y-1/2',
						'w-screen',
						'h-screen',
						'video-bg',
					)}
				></iframe>
			</div>
		</div>
	);
};

export const VideoPlayer: FC<Props> = ({videoId, title}: Props) => {
	const [clickPlay, setClickPlay] = useState(false);

	const videoParams = `autoplay=0&loop=0&autopause=0&title=0&byline=0&portrait=0&controls=${
		clickPlay ? '1' : '0'
	}&muted=0&background=0`;

	const handleClick = useCallback(() => {
		setClickPlay(true);
	}, []);

	return (
		<div className={clsx('pt-[56.25%]', 'relative', 'w-full', 'h-full')}>
			{!clickPlay && (
				<button
					onClick={handleClick}
					type="button"
					aria-label="Spill av video"
					className={clsx('group', 'z-20', 'absolute', 'inset-0')}
				>
					<span
						aria-hidden="true"
						className={clsx(
							'bg-black/80',
							'group-hover:bg-black',
							'hover:bg-black',
							'group-focus:bg-black',
							'focus:bg-black',
							'w-[70px]',
							'h-[70px]',
							'inline-flex',
							'justify-center',
							'items-center',
							'rounded-full',
						)}
					>
						<Play />
					</span>
				</button>
			)}
			<iframe
				name="vimeo-player"
				title={title ?? 'vimeo-player'}
				src={`https://player.vimeo.com/video/${videoId}?${videoParams}`}
				allow="fullscreen;"
				className={clsx('absolute', 'top-0', 'left-0', 'w-full', 'h-full')}
			></iframe>
		</div>
	);
};
