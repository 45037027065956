import type {ReactNode} from 'react';

import {PortableLink} from '@/components/portable-text/portable-link';
import type {LinkFragment} from '@/sanity/schemas/primitives/link';
import {resolveLinkHref} from '@/shared';

type Props = {
	children: ReactNode;
	value: LinkFragment;
};

export const PortableLinkContainer = ({children, value}: Props) => {
	return (
		<PortableLink href={resolveLinkHref(value)} targetBlank={value?.targetBlank}>
			{children}
		</PortableLink>
	);
};
