import type {ReactNode} from 'react';

import {cn} from '@/shared';

type Props = {
	children: ReactNode;
	className?: string;
};

export const BulletList = ({children, className}: Props) => {
	return (
		<ul className={cn(['my-8', 'space-y-4', 'list-disc', 'ml-8', 'text-inherit', className])}>
			{children}
		</ul>
	);
};
