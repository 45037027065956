import type {FC, PropsWithChildren} from 'react';

import {cn} from '@/shared';

type Props = PropsWithChildren<{
	className?: string;
}>;

export const CallToActionGroup: FC<Props> = ({className, children}) => {
	return <div className={cn(['flex', 'gap-4', 'flex-wrap', 'mb-4', className])}>{children}</div>;
};
