import {Figure} from '@/components/figure';
import {ImageBlock} from '@/components/image/ImageBlock';
import type {ImageWeb} from '@/components/image/types';

interface Props {
	image: ImageWeb;
}

const classNameOverride = {
	figure: 'my-6',
};

export const PortableImage = ({image}: Props) => {
	if (!image) {
		return null;
	}

	if (image.caption || image.credit) {
		return (
			<Figure
				credit={image.credit}
				caption={image.caption}
				classNameOverride={classNameOverride}
			>
				<ImageBlock
					height={image.image.height}
					src={image.image.src}
					width={image.image.width}
					alt={image.altText ?? ''}
				/>
			</Figure>
		);
	}

	return (
		<ImageBlock
			height={image.image.height}
			src={image.image.src}
			width={image.image.width}
			alt={image.altText ?? ''}
		/>
	);
};
